<template>
  <div>
    <Toolbar :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{
          `${$options.servicePrefix} ${item["@id"]}`
        }}</v-toolbar-title>
      </template>
    </Toolbar>

    <br />

    <div v-if="item" class="table-intervenant-show">
      <v-simple-table>
        <template slot="default">
          <tbody>
            <tr>
              <!-- <tb><strong>{{ $t('nom') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["nom"] }}
                </v-col>
              </tb>

              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["evenement"].nom }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('siteWeb') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["siteWeb"] }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('facebook') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["facebook"] }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('twitter') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["twitter"] }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('youtube') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["youtube"] }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('image') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["image"] }}
                </v-col>
              </tb>

               <!-- <tb><strong>{{ $t('descriptionCourt') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["descriptionCourt"] }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('descriptionComplet') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                   <span v-html="item['descriptionComplet']"></span>
                </v-col>
              </tb>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import Loading from "../../components/Loading";
import ShowMixin from "../../mixins/ShowMixin";
import Toolbar from "../../components/Toolbar";

const servicePrefix = "Intervenant";

export default {
  name: "IntervenantShow",
  servicePrefix,
  components: {
    Loading,
    Toolbar,
  },
  mixins: [ShowMixin],
  computed: {
    ...mapFields("intervenant", {
      isLoading: "isLoading",
    }),
    ...mapGetters("intervenant", ["find"]),
  },
  methods: {
    ...mapActions("intervenant", {
      deleteItem: "del",
      reset: "reset",
      retrieve: "load",
    }),
  },
};
</script>
